import React from 'react';
import "./App.css";

function Ping({ data }) {
    return (
        <div className="ping">
            <p>PING {data.destination} ({data.destination_ip}) {data.data_bytes}(84) bytes of data.</p>
            <br/>
            <p>--- {data.destination} ping statistics ---</p>
            {data.packet_loss_percent !== null ? (
                <div>
                    <p>{data.packets_transmitted} packets transmitted, {data.packets_received} received, {data.packet_loss_percent}% packet loss, time {data.time_ms}ms</p>
                    <p>rtt min/avg/max/mdev = {data.round_trip_ms_min}/{data.round_trip_ms_avg}/{data.round_trip_ms_max}/{data.round_trip_ms_stddev} ms</p>
                </div>
                ) : (
                <div>
                    <p>{data.packets_transmitted} packets transmitted, {data.packets_received} received, 100% packet loss</p>
                </div>
            )}
      </div>
    );
}

export default Ping;
