import React from 'react';
import "./App.css";

function AS151381() {
    return (
        <div className="about">
            <h1 id="about-as151381">AS151381</h1>
            <p>Our AS151381 is operated in Equinix TY8 and provides high availability and stability.<br/>
Our network is reachable via the rack's upstream transit to the major IXs JPNAP, BBIX, and JPIX with remarkably low latency.<br/>
We also contribute to a very neutral Internet Exchange Point in the same rack, INXP Asia,</p>
            {/* <div className="bgp-he" style={{ height: "400px" , display: "flex" }} >
                <img src="https://bgp.he.net/graphs/as151381-ipv4.svg"  alt="AS151381" />
                <img src="https://bgp.he.net/graphs/as151381-ipv6.svg"  alt="AS151381" />
            </div> */}
      </div>
    );
}

export default AS151381;
