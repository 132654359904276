import React from 'react';
import "./App.css";
function Status() {
    return (
        <div className="status">
            <br />
            <div>
                <iframe
                    title="status"
                    width="100%"
                    height="830px"
                    src="https://circle.betteruptime.com/"
                    data-controller="status-pages-v2--sections"
                    >
                </iframe>
            </div>
        </div>
    );
}

export default Status;
