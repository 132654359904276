import React, { useState, useEffect } from 'react';
import { Button, Alert, Collapse, Form, Col, Badge, Row, Spinner, InputGroup } from 'react-bootstrap';
import MediaQuery from 'react-responsive';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Mtr from './Mtr';
import Ping from './Ping';
import Bgp from './Bgp';
import Lookingglass from './Lookingglass';
import About from './About';
// import Traffic from './Traffic';
import Status from './Status';
import AS151381 from './AS151381';
import Contact from './Contact';
import { useParams } from "react-router-dom";


function App() {
  const { ipparam } = useParams();
  const { prefixparam } = useParams();
  const { actionparam } = useParams();
  const [data, setData] = useState(null);
  const [ip, setIp] = useState(null);
  const [main, setMain] = useState(true);
  const [submitAction, setSubmitAction] = useState(null);
  const [error, setError] = useState(null);
  const [errorDesc , setErrorDesc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [userip, setUserIp] = useState(null);

  const req = async (url) => {
    try {
      const response = await fetch(url);
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      return { id: 7 , error: "Error fetching data" , description : "Failed to connect to API. There may be a temporary outage." }
    }
  };

  const fetchData = async (ip, action, routerid) => {
    setLoading(true);
    const url = `https://api.circleglobalnetwork.com/api/v1/${action}?ip=${ip}${!action === 'bgp' ? '' : `&routerid=${routerid}`}`;
    const jsonData = await req(url);
    if (jsonData.error) {
      setError(jsonData.error);
      setErrorDesc(jsonData.description);
    }
    else {
      setData(jsonData);
    }
    setLoading(false);
    setOpen(true);
  };

  const run = (ip, action, routerid) => {
    setMain(false);
    setOpen(false);
    setData(null);
    setError(null);
    setIp(ip);
    setSubmitAction(action);
    fetchData(ip, action, routerid);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    run(event.target.ip.value, event.target.actiontype.value, event.target.router.value);
  }; 

  const custip = async () => {
    const url = 'https://api.circleglobalnetwork.com/api/v1/get_ip';
    const jsonData = await req(url);
    return jsonData.ip;
  }

useEffect(() => {
    if (!ipparam && actionparam) {
      custip().then((ip) => {
        setIp(ip);
        run(ip, actionparam,1);
      }); 
    }
    else if (ipparam && ['ping', 'bgp', 'mtr'].includes(actionparam)) {
      if (actionparam === 'bgp' && prefixparam >= 0 && prefixparam <= 128) {
        const combinedIP = `${ipparam}/${prefixparam}`;
        setIp(combinedIP);
        run(combinedIP, 'bgp',1);
      } else {
        run(ipparam, actionparam,1);
      }
    }
    else {
      custip().then((ip) => {
        setUserIp(ip);
      }
    );
  }
  }, []);

return (
<div className="App">
  <div className="form">
    <Form onSubmit={handleSubmit}>
    <MediaQuery minWidth={800}>
    {(matches) =>
      matches ? (
        <div>
        <Row className="align-items-center">
          <Col xs={7}>
            <InputGroup>
              <InputGroup.Text>IP Address</InputGroup.Text>
              <Form.Control id="ip" type="text" placeholder="Enter IP" />
              </InputGroup>
          </Col>
          <Col xs={2}>
            <Form.Select id="actiontype">
              <option value="bgp">BGP</option>
              <option value="ping">Ping</option>
              <option value="mtr">MyTraceroute</option>
            </Form.Select>
          </Col>
          <Col xs={2}>
            <Form.Select id="router">
              <option value="1">Tokyo-1</option>
              <option value="2">Tokyo-2</option>
              </Form.Select>
          </Col>
          <Col xs={1}>
            <Button type="submit">Submit</Button>
          </Col>
        </Row>
      </div>
      ) : (
      <div>
        <InputGroup>
          <InputGroup.Text>IP Address</InputGroup.Text>
          <Form.Control id="ip" type="text" placeholder="Enter IP" />
        </InputGroup>
        <br/>
        <Form.Select id="actiontype">
          <option value="ping">Ping</option>
          <option value="mtr">MyTraceroute</option>
          <option value="bgp">BGP</option>
        </Form.Select>
        <br/>
        <Form.Select id="router">
          <option value="1">Tokyo-1</option>
          <option value="2">Tokyo-2</option>
        </Form.Select>
        <br/>
        <Button type="submit">Submit</Button>
      </div>
      )
    }
    </MediaQuery>
    {loading &&<div><br/><Spinner animation="border" /></div>}
    </Form>
  </div>
  <br/>
  {main && <Alert variant="primary"><p>Your IP address is <strong><a href={`https://circleglobalnetwork.com/bgp/${userip}`} style={{color: "#052C65"}}>{userip}</a></strong></p></Alert>}
  {/* {main && <Alert variant="light"><About /></Alert>} */}
  {main && <Alert variant="light"><AS151381 /></Alert>}
  {/* {main && <Alert variant="light"><Lookingglass /></Alert>} */}
  {main && <Alert variant="light"><Status /></Alert>}
  {/* {main && <Alert variant="light"><Traffic /></Alert>} */}
  {main && <Alert variant="light"><Contact /></Alert>}
  <Collapse in={open} id = "open">
    <div>
      {error && 
      <Alert variant="danger">
        <Alert.Heading>{error}</Alert.Heading>
        <pre>{errorDesc}</pre>
      </Alert>}
      {data && (
        <Alert variant="dark">
          <Alert.Heading>
            {submitAction === 'ping' && `Ping to ${ip} `}
            {submitAction === 'mtr' && `MTR to ${ip} `}
            {submitAction === 'bgp' && `BGP route to ${ip} `}
            <Badge bg="success"> Success</Badge>
          </Alert.Heading>
          {submitAction === 'ping' && <Ping data={data} />}
          {submitAction === 'mtr' && <Mtr data={data} />}
          {submitAction === 'bgp' && <Bgp data={data} />}
        </Alert>
      )}
    </div>
  </Collapse>
</div>
  );
}

export default App;