import React from 'react';
import Table from 'react-bootstrap/Table';
import "./App.css";

function ReturnHost(host) {
    const returnString = [];
    const hostResult = host.match(/(.+?)\s*\(/);
    const ipresult = host.match(/\(([^)]+)\)/);
    returnString[0] = hostResult ? hostResult[1] : host;
    returnString[1] = ipresult ? ipresult[1] : host;
    return returnString;
 }

function Mtr({ data }) {
    return (
        <div className="table">
            <Table striped bordered hover variant="dark" responsive>
                <thead>
                    <tr>
                        <th>Count</th>
                        <th>ASN</th>
                        <th>IP</th>
                        <th>Loss%</th>
                        <th>Snt</th>
                        <th>Last</th>
                        <th>Avg</th>
                        <th>Best</th>
                        <th>Wrst</th>
                        <th>StDev</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} >
                            <td>{item.count}</td>
                            <td>{item.ASN && item.ASN.match(/AS(\d+)/) ? (<a href={`https://bgp.tools/as/${item.ASN.match(/AS(\d+)/)[1]}`} target="_blank" rel="noreferrer">AS{item.ASN.match(/AS(\d+)/)[1]}</a>) : ("AS???")}</td>                
                            <td><a href={`https://bgp.tools/prefix/${ReturnHost(item.host)[1]}`} target="_blank" rel="noreferrer">{ReturnHost(item.host)[0]} ({ReturnHost(item.host)[1]})</a></td>
                            <td>{item['Loss%']}</td>
                            <td>{item.Snt}</td>
                            <td>{item.Last}</td>
                            <td>{item.Avg}</td>
                            <td>{item.Best}</td>
                            <td>{item.Wrst}</td>
                            <td>{item.StDev}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default Mtr;
