import React from 'react';
import { Alert } from 'react-bootstrap';

function Bgp({ data }) {
    const { status, paths } = data;
    return (
        <div className="bgp">
            <Alert variant="info">
                <pre>{status}</pre>
            </Alert>
            {paths.map((path, index) => (
                <Alert key={index} variant={path.best ? "success" : "secondary"}>
                    <pre>{path.path}</pre>
                </Alert>
            ))}
        </div>
    );
}

export default Bgp;
