import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import White from './White';
const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/empty" element={<White />} />
      <Route path="/:actionparam" element={<App />} />
      <Route path="/:actionparam/:ipparam" element={<App />} />
      <Route path="/:actionparam/:ipparam/:prefixparam" element={<App />} />

    </Routes>
  </Router>
);