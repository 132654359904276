import React from 'react';
import "./App.css";

function White() {
    return (
        <div className="App">
            <h1>Welcome to CIRCLE</h1>
      </div>
    );
}

export default White;
