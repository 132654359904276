import React from 'react';
import "./App.css";

function Contact() {
    const year = new Date().getFullYear();
    return (
        <div className="contact">
            <h1 id="about-contact">Contact</h1>
            <p>Please contact us at info@circleglobalnetwork.com for any abuse or technical issues.</p>
            <p>© {year} CIRCLE All Rights Reserved. Akabane Daiichi Hayama Building 4F, 1-7-9 Akabane, Kita-ku, Tokyo 115-0045, Japan</p>
      </div>
    );
}

export default Contact;
